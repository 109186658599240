import { useState } from 'react';

import { useRouter } from 'next/router';

import { DateRangeInput } from '@dribe-io/ui-kit/components';
import { DateRangeStateProvider } from '@dribe-io/ui-kit/components/DateRangePicker';
import { sizes } from '@dribe-io/ui-kit/theme/tokens/breakpoints';

import { useTranslation } from 'next-i18next';

import { Maybe, GetLocationsQuery_Query } from '@api/dribe';
import RouteNames from '@constants/RouteNames';
import { useWindowSize } from '@hooks/useWindowSize';
import { trackLink, LinkTitles } from '@lib/rudderstack/trackers';
import { generateNonEmptyQueryParamsFromObject } from '@utils/jsonUtils';
import { getDisplayMonths } from '@utils/stringUtils';

import * as S from './QuickSearchForm.styled';

export interface QuickSearchFormProps {
  formTitle: Maybe<string>;
  locationDropdownLabel: Maybe<string>;
  locationDropdownPlaceholder: Maybe<string>;
  locations?: GetLocationsQuery_Query['locations'];
  dateInputLabel: Maybe<string>;
  dateInputPlaceholder: Maybe<string>;
  searchButtonLabel: Maybe<string>;
}

export const QuickSearchForm = ({
  formTitle,
  searchButtonLabel,
  locationDropdownLabel,
  locationDropdownPlaceholder,
  locations,
  dateInputLabel,
  dateInputPlaceholder
}: QuickSearchFormProps) => {
  const { t } = useTranslation();
  const [locationId, setLocationId] = useState('');
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const router = useRouter();
  const { width } = useWindowSize();
  const { locale } = router;

  const showMobileDatePicker = !!(width && width <= sizes.md);

  const onlyOneSelectedDate = selectedDates.length === 1;

  const locationOptions = [
    { label: locationDropdownPlaceholder || '', value: '' },
    ...(locations?.data || []).map(location => ({
      label: location.name,
      value: location.id
    }))
  ];

  const searchParams = {
    locationId: locationId,
    pickupStart:
      (!onlyOneSelectedDate && selectedDates[0]?.toISOString()) || '',
    pickupEnd: (!onlyOneSelectedDate && selectedDates[1]?.toISOString()) || ''
  };

  const garageQueryParams = generateNonEmptyQueryParamsFromObject(searchParams);

  const handleDateSelection = (dates: Date[]) => {
    setSelectedDates(dates);
  };

  const onReset = () => {
    setSelectedDates([]);
  };

  const submitSearch = (href: string) => {
    trackLink(LinkTitles.LANDING_HERO_SEARCH_LINK, href, searchParams);
    router.push(href);
  };

  return (
    <S.FormContainer>
      <S.FormTitle element="h4" color="petrol">
        {formTitle}
      </S.FormTitle>
      <S.InlineSearch alignItems={'center'} mt={24}>
        <S.LocationDropdown
          icon={'location'}
          options={locationOptions}
          onChange={({ value }) => setLocationId(value)}
          placeholder={locationDropdownPlaceholder || ''}
          label={locationDropdownLabel || ''}
          optionsStyle={{
            padding: 24
          }}
        />
        <S.DateRangeInputDropdown>
          <DateRangeStateProvider
            key={locale}
            config={{
              selectedDates: selectedDates,
              onDatesChange: handleDateSelection,
              locale: { locale }
            }}
          >
            <DateRangeInput
              enableRangePicker
              onReset={onReset}
              placeholder={dateInputPlaceholder || ''}
              label={dateInputLabel || ''}
              dateRangePickerProps={{
                className: 'calendar',
                showMobileNavigation: true,
                forceStacked: showMobileDatePicker,
                calendarMonthCount: showMobileDatePicker ? 1 : 2,
                monthsOverride: getDisplayMonths(t)
              }}
            />
          </DateRangeStateProvider>
        </S.DateRangeInputDropdown>
        <S.SearchButtonWrapper>
          <S.SearchButton
            variant={'primary'}
            iconStyleObject={{ firstPrimary: 'snow' }}
            icon={'search'}
            text={searchButtonLabel}
            onClick={() =>
              submitSearch(`${RouteNames.GARAGE}?${garageQueryParams}`)
            }
          />
        </S.SearchButtonWrapper>
      </S.InlineSearch>
    </S.FormContainer>
  );
};

export default QuickSearchForm;
